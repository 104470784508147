export default async function EnviarEmail({crewCode, email, timestamp}) {

    const url = "/LoginPage/procesos/EnviarEmail.php";
    const res = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            crewCode, email, timestamp
        }),
    })
    
    if(res.status == 200){
        return await res.text()
    }else{
        return "Error obteniendo los datos"
    }
}