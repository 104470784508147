export default async function CheckCode({crewCode, code}) {

    const url = "/LoginPage/procesos/CheckCode.php";
    const res = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            crewCode, code
        }),
    })
    
    if(res.status == 200){
        return await res.text()
    }else{
        return "Error obteniendo los datos"
    }
}